/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { PlanUpdateRates } from './../../../employer/src/lib/employer-routes/plans/models/EmployerPlanUpdate';
import { Employee } from '@besc/employee';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface PlanCost {
    total: number;
    employer: number;
}

export interface EmployerPlan {
    id: string;
    employerId: string;
    healthPlanId: string;
    planGenericName: string;
    effectiveDate: string;
    terminationDate: string;
    networkName: string;
    healthPlan?: SimplePlan;
    fullHealthPlan?: Plan;
    monthlyCost?: PlanCost;
    dependentCount?: number;
    employeeCount?: number;
    contributions?: EmployerHealthPlanContribution;
    employees?: Employee[];
    employeeIds?: string[];
    planStatus: 'active' | 'quote';
    planRates?: PlanUpdateRates;
    switched_to_active?: string;
    datetimeCreated?: string;
}

export interface Issuer {
    address: string;
    city: string;
    dbaName: string;
    hios: string;
    id: string;
    isCustom: boolean;
    legalName: string;
    logoUrl: string;
    name: string;
    phone: string;
    state: string;
    website: string;
    zipCode: string;
}

export interface IssuerRegistration {
    id: string;
    issuer: Issuer;
    issuerYear: string;
    hios: string;
    name: string;
    legalName: string;
    dbaName: string;
    city: string;
    address: string;
    zipCode: string;
    phone: string;
    website: string;
    logoUrl: string;
    isCustom: boolean;
    nameAbbreviated?: string;
    stateFull: string;
    stateAbbreviated: string;
}

export interface SimplePlanDetails {
    deductibleSingle: number;
    deductibleFamily: number;
    deductibleType: DeductibleType;
    oopSingle: number;
    oopFamily: number;
    coinsuranceMember: number;
    rxDeductibleSingle: string;
}

export interface SimplePlan {
    id: string;
    name: string;
    hsaEligible: boolean;
    networkName: string;
    details: SimplePlanDetails;
    issuer: IssuerRegistration;
    isCustom: boolean;
    onMarket: boolean;
    offMarket: boolean;
    sbcLink: string;
}

export interface PlanInfo {
    id: string;
    datetimeCreated: string;
    serviceAreaId: string;
    planAreaId: string;
    hios: string;
    year: number;
    type: PlanType;
    actuarialValue: number;
    metallicLevel: string;
    networkName: string;
    networkSize: number;
    hsaEligible: boolean;
    gatekeeper: boolean;
    calendar: PlanCalendar;
    fundingType: PlanFundingType;
    sbcLink: string;
    logoUrl: string;
    onMarket: boolean;
    offMarket: boolean;
}

export interface PlanDetails {
    actuarialValue: number;
    deductibleSingle: number;
    deductibleFamily: number;
    deductibleType: DeductibleType;
    oopSingle: number;
    oopFamily: number;
    coinsuranceMember: number;
    deductibleSingleNonNetwork: number;
    deductibleFamilyNonNetwork: number;
    oopSingleNonNetwork: number;
    oopFamilyNonNetwork: number;
    rxMedicalDeductibleIntegration: boolean;
    rxDeductibleSingleValue: number;
    rxDeductibleFamilyValue: number;
    rxDeductibleSingle: string;
    rxDeductibleFamily: string;
    rxOopSingle: string;
    rxOopFamily: string;
}

export interface PlanCoverageDetails {
    preventiveCare: string;
    pcpString: string;
    pcpValue: number;
    pcpValueType: DoctorsOfficeValueType;
    pcpDedWaiver: boolean;
    pcpWaiverLimit: number;
    pcpTiers: boolean;
    specialistString: string;
    specialistValue: number;
    specialistValueType: DoctorsOfficeValueType;
    specialistDedWaiver: boolean;
    specialistWaiverLimit: number;
    specialistTiers: boolean;
    urgentCare: string;
    outOfNetworkCoverage: boolean;
    durableMedicalEquipment: string;
    diagnosticTest: string;
    ambulance: string;
    emergencyRoom: string;
    homeHealthCare: string;
    hospice: string;
    imaging: string;
    imagingCenter: string;
    imagingPhysician: string;
    inpatientBirth: string;
    prenatalCare: string;
    postnatalCare: string;
    inpatientFacility: string;
    outpatientFacility: string;
    inpatientPhysician: string;
    outpatientPhysician: string;
    inpatientMentalHealth: string;
    outpatientMentalHealth: string;
    inpatientSubstanceAbuse: string;
    outpatientSubstanceAbuse: string;
    rehabilitationServices: string;
    skilledNursing: string;
    chiropracticServices: boolean;
}

export interface Plan {
    id: string;
    name: string;
    effectiveDate: string;
    expirationDate: string;
    currentPlan?: boolean;
    info: PlanInfo;
    details: PlanDetails;
    coverage: PlanCoverageDetails;
    issuer?: Issuer;
    planRates?: PlanRates;
    rates?: PlanRates[];
    stateFactors?: StateFactors;
}

// export type Plan = PlanInfo & PlanDetails & PlanCoverageDetails;
export interface EmployerContribution {
    method: HealthPlanContributionMethod;
    value: number;
}
export interface EmployerCompositeContribution {
    employeeOnly: EmployerContribution;
    employeeSpouse: EmployerContribution;
    employeeChildren: EmployerContribution;
    family: EmployerContribution;
}
export interface EmployerRateBuildUpContribution {
    employee: EmployerContribution;
    dependents: EmployerContribution;
}

export interface Contribution {
    familyTiers: 'composite' | 'rate_build_up';
    composite?: EmployerCompositeContribution;
    rateBuildUp?: EmployerRateBuildUpContribution;
}

export interface EmployerHealthPlanContribution extends Contribution {
    id?: string;
    payPeriods?: number;
}

export interface CreateExistingPlanRequest {
    planId: string;
    effectiveDate: string;
    expirationDate: string;
    zipCode: string;
    planStatus: string;
    planGenericName: string;
    planRates?: PlanRates;
    contributions?: EmployerHealthPlanContribution;
}

export enum RateType {
    AgeRate = 'age_rate',
    Composite = 'composite',
}

export interface AgeRateCurveResponse extends AgeRates {
    id: string;
    isFederalDefault: boolean;
}

export interface AgeRates {
    age0: number;
    age1: number;
    age2: number;
    age3: number;
    age4: number;
    age5: number;
    age6: number;
    age7: number;
    age8: number;
    age9: number;
    age10: number;
    age11: number;
    age12: number;
    age13: number;
    age14: number;
    age15: number;
    age16: number;
    age17: number;
    age18: number;
    age19: number;
    age20: number;
    age21: number;
    age22: number;
    age23: number;
    age24: number;
    age25: number;
    age26: number;
    age27: number;
    age28: number;
    age29: number;
    age30: number;
    age31: number;
    age32: number;
    age33: number;
    age34: number;
    age35: number;
    age36: number;
    age37: number;
    age38: number;
    age39: number;
    age40: number;
    age41: number;
    age42: number;
    age43: number;
    age44: number;
    age45: number;
    age46: number;
    age47: number;
    age48: number;
    age49: number;
    age50: number;
    age51: number;
    age52: number;
    age53: number;
    age54: number;
    age55: number;
    age56: number;
    age57: number;
    age58: number;
    age59: number;
    age60: number;
    age61: number;
    age62: number;
    age63: number;
    age64: number;
    age65: number;
}

export interface CompositeRates {
    single: number;
    singleAndChildren: number;
    singleAndSpouse: number;
    family: number;
}

export interface Rates {
    type: RateType;
    age: AgeRates;
    tobacco: AgeRates;
    composite: CompositeRates;
}

export interface PlanRates {
    id: string;
    effectiveDate: string;
    expirationDate: string;
    planId: string;
    rates: Rates;
}

export interface StateFactors {
    id: string;
    state: string;
    year: number;
    employeeOnly: number;
    employeeAndSpouse: number;
    employeeAndChildren: number;
    family: number;
}

export interface CompositePremiums {
    employee: number;
    employeeAndSpouse: number;
    employeeAndChildren: number;
    family: number;
}

export enum HealthPlanContributionMethod {
    Percentage = 'percentage',
    Fixed = 'fixed_withholding',
    Defined = 'defined_contribution',
}

export enum AdultChildCarveOutMethod {
    None = 'none',
    Percentage = 'percentage',
    DefinedContribution = 'defined_contribution',
}

export interface ChangeListEmployee extends Employee {
    changing?: boolean;
    effectiveDate?: moment.Moment;
    terminationDate?: moment.Moment;
}

export interface UpdateEmployeeOnPlan {
    employeeId: string;
    planId?: string;
    effectiveDate?: Moment;
    terminationDate?: Moment;
    electionId?: string;
    remove?: boolean;
}

export interface CompositeRateRequest {
    planId: string;
    employerId: string;
    employerPlanId: string;
    effectiveDate: Moment;
    planEffectiveDate: Moment;
    zipCode: string;
}

export type UpdateEmployeesOnPlan = UpdateEmployeeOnPlan[];

export enum PlanType {
    PPO = 'PPO',
    POS = 'POS',
    HMO = 'HMO',
}

export enum PlanFundingType {
    FullyInsured = 'fully_insured',
    LevelFunded = 'level_funded',
    SelfFunded = 'self_funded',
}

export enum PlanMetallicLevel {
    Platinum = 'platinum',
    Gold = 'gold',
    Silver = 'silver',
    Bronze = 'bronze',
    ExpandedBronze = 'expanded_bronze',
}

export enum PlanCalendar {
    PlanYear = 'plan_year',
    CalendarYear = 'calendar_year',
}

export enum DeductibleType {
    Aggregate = 'aggregate',
    Embedded = 'embedded',
}

export enum DoctorsOfficeValueType {
    Copay = 'copay',
    Coinsurance = 'coinsurance',
}
