/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HealthPlanContributionMethod } from '../../interfaces';

@Component({
    selector: 'besc-contribution-slider',
    templateUrl: './contribution-slider.component.html',
    styleUrls: ['./contribution-slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContributionSliderComponent),
            multi: true,
        },
    ],
})
export class ContributionSliderComponent
    implements OnInit, ControlValueAccessor, OnDestroy {
    private _onDestroy$ = new Subject();
    private _type: BehaviorSubject<string> = new BehaviorSubject<string>(
        HealthPlanContributionMethod.Percentage
    );

    @Input() label: string;

    sliderForm = this._fb.group({
        type: [HealthPlanContributionMethod.Percentage],
        sliderValue: [100],
        numberValue: [100],
    });

    sliderConfig$ = this._type.pipe(
        map((value) => {
            if (value === HealthPlanContributionMethod.Percentage) {
                return {
                    max: 100,
                    min: 0,
                    step: 1,
                };
            }
            
            return {
                max: 1000,
                min: 0,
                step: 25,
            };
        })
    );

    onChangeFn = (_: any) => {};

    onTouchedFn = () => {};

    constructor(private _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.sliderForm
            .get('sliderValue')
            .valueChanges.pipe(takeUntil(this._onDestroy$))
            .subscribe((value) => {
                this.sliderForm
                    .get('numberValue')
                    .setValue(value, { emitEvent: false });
                this.onChangeFn({
                    method: this.sliderForm.value.type,
                    value: value,
                });
                this.onTouchedFn();
            });
        this.sliderForm
            .get('numberValue')
            .valueChanges.pipe(takeUntil(this._onDestroy$))
            .subscribe((value) => {
                this.sliderForm
                    .get('sliderValue')
                    .setValue(value, { emitEvent: false });
                this.onChangeFn({
                    method: this.sliderForm.value.type,
                    value: value,
                });
                this.onTouchedFn();
            });
        this.sliderForm
            .get('type')
            .valueChanges.pipe(takeUntil(this._onDestroy$))
            .subscribe((value) => {
                this._type.next(value);
                this.sliderForm.patchValue({
                    sliderValue: value === 'percentage' ? 100 : 0,
                    numberValue: value === 'percentage' ? 100 : 0,
                });
            });
    }

    ngOnDestroy() {
        this._onDestroy$.next();
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    writeValue(obj: any): void {
        this.sliderForm.setValue(
            {
                type: obj.method,
                sliderValue: obj.value,
                numberValue: obj.value,
            },
            {
                emitEvent: false,
            }
        );
        this._type.next(obj.method);
        this.sliderForm.markAsPristine();
    }
}
