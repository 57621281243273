import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import {
  Router,
  NavigationStart
} from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { NavigationItem } from '@besc/layout';
import { AgencyService, AgencyStatus } from '@besc/agency';
import { RoleType, UserService } from '@benefit-sculptor/auth';

@Component({
  selector: 'besc-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  isAgentAdmin: boolean;
  isAccessingAs: boolean;
  navigationItems$ = new BehaviorSubject<NavigationItem[]>([]);
  private _destroy = new Subject<void>();

  constructor(
    private _router: Router,
    private _agency: AgencyService,
    private _user: UserService,
  ) { }

  ngOnInit(): void {
    this.setNavigationItems();
  }

  setNavigationItems(): void {
    combineLatest([
      this._user.currentUser$,
      this._agency.userAgency$
    ]).pipe(
      tap(([user, agency]) => {
        this.isAgentAdmin = user.userType.toLowerCase() === RoleType.ADMIN_AGENT;
        this.isAccessingAs = localStorage.getItem('AS_USER') !== null && localStorage.getItem('AS_USER_AGENCY') !== null;
        const employerId = this._getEmployerIdFromURL(this._router.url);
        const navigationItems: NavigationItem[] = [
          {
            label: 'Home',
            link: ['/app/home'],
            notExact: false,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          },
          {
            label: 'Customer Data',
            link: ['/app/small-employers'],
            notExact: true,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          },
          {
            label: 'Custom Plans',
            link: ['/app/agency/custom-plans/'],
            notExact: true,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          }
        ];

        if (employerId) {
          navigationItems.splice(2, 0, {
            label: 'Quotes',
            link: [`/app/small-employers/${employerId}/quotes`],
            notExact: true,
            disabled: employerId == null,
            isActive: false,
          });
        }

        if (this.isAgentAdmin && !this.isAccessingAs) {
          navigationItems.splice(1, 0, {
            label: 'Admin Center',
            notExact: false,
            link: ['/app/admin-center'],
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          })
        }
  
        this.navigationItems$.next(navigationItems.map((item) => {
          item.link = [
            this._router.serializeUrl(
              this._router.createUrlTree(
                typeof item.link === 'string'
                  ? [item.link]
                  : item.link
              )
            ),
          ];
          item.isActive = this._isActiveURL(this._router.url, item.link, item.notExact);
  
          return item;
        }));
      }),
      tap(() => this._watchRoutingChanges()),
      takeUntil(this._destroy)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  navigateItem(item: NavigationItem, $event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (!item.disabled) {
      this._router.navigate(
        typeof item.link === 'string' ? [item.link] : item.link
      );
    }
  }

  private _watchRoutingChanges(): void {
    this._router.events
    .pipe(
      takeUntil(this._destroy)
    )
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        const employerId = this._getEmployerIdFromURL(event.url);
        let currentNavigationItems: NavigationItem[] = this.navigationItems$.value;
        if (employerId && (
          (this.isAgentAdmin && currentNavigationItems.length === 4)
          || (!this.isAgentAdmin && currentNavigationItems.length === 3))
        ) {
          const item = {
            label: 'Quotes',
            link: [`/app/small-employers/${employerId}/quotes`],
            notExact: true,
            disabled: employerId == null,
            isActive: false,
          };
          item.link = [
            this._router.serializeUrl(
              this._router.createUrlTree(
                typeof item.link === 'string'
                  ? [item.link]
                  : item.link
              )
            ),
          ];
          item.isActive = this._isActiveURL(this._router.url, item.link, item.notExact);
          if (this.isAgentAdmin && !this.isAccessingAs) {
            currentNavigationItems.splice(3, 0, item);
          } else {
            currentNavigationItems.splice(2, 0, item);
          }
        } else if (!employerId) {
          currentNavigationItems = currentNavigationItems.filter((item) => item.label !== 'Quotes');
        }

        currentNavigationItems = currentNavigationItems.map(item => {
          item = { ...item, isActive: this._isActiveURL(event.url, item.link, item.notExact) };
          if (item.link[0].includes('quotes')) {
            if (employerId) {
              item = {
                ...item,
                disabled: false,
                link: [
                  this._router.serializeUrl(
                    this._router.createUrlTree([`/app/small-employers/${employerId}/quotes`])
                  ),
                ]
              };
            } else {
              item = { ...item, disabled: true };
            }
          }
          return item;
        });
        this.navigationItems$.next(currentNavigationItems);
      }
    });
  }

  private _getEmployerIdFromURL(url: string): string | null {
    if (!url.includes('/app/small-employers')) {
      return null
    }

    const splits = url.split('/')
    if (splits.length > 3 && splits[3] !== 'list') {
      return splits[3]
    }

    return null
  }

  private _isActiveURL(url: string, links: string[], notExact: boolean): boolean {
    const link = links[0];

    const isActive = this._router.isActive(
      this._router.createUrlTree(links, {
        queryParamsHandling: 'preserve',
      }),
      !notExact
    );

    if (link.includes('/quotes')) {
      return url.includes('/quotes');
    }

    if (url.includes('/quotes')) {
      return false;
    }

    return isActive;
  }
}
