import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SummaryTableData } from '../models/SummaryTableData.model';

@Component({
    selector: 'besc-summary-table',
    templateUrl: './summary-table.component.html',
    styleUrls: ['./summary-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryTableComponent {
    @Input() data: SummaryTableData[] = [];
    @Input() colSize = 6;
    @Input() highlightKey = false;
    @Output() actionClicked = new EventEmitter<void>();
}
