/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { FormHelpersModule } from "@benefit-sculptor/core";
import { SetContributionsComponent } from "./set-contributions.component";
import { RatesDetailsModalModule } from "../rates-details-modal/rates-details-modal.module";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormHelpersModule,
        NgSelectModule,
        RatesDetailsModalModule,
    ],
    declarations: [SetContributionsComponent],
    exports: [SetContributionsComponent],
})
export class SetContributionsModule {}