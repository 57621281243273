/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RatesDetailsModalComponent } from "./rates-details-modal.component";
import { SummaryTableModule } from "@besc/ui";
import { SharedModule } from "@besc/shared";

@NgModule({
    imports: [
        CommonModule,
        SummaryTableModule,
        SharedModule,
    ],
    declarations: [RatesDetailsModalComponent],
    exports: [RatesDetailsModalComponent],
})
export class RatesDetailsModalModule {}