<div class="contributions-form" [formGroup]="contributionsForm">
    <div class="contributions-type">
        <span>Contribution Type: {{contributionsForm.value.type === 'composite' ? 'Composite' : 'Rate Build Up'}}</span>
        <a class="plan-rates" (click)="openPlanRatesModal()" *ngIf="hasEmployees$ | async">See Plan Rates</a>
    </div>
    <hr />
    <div class="" *ngIf="contributionsLabels$ | async as contributionsLabels">
        <div class="row">
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value1"
                    formControlName="value1"
                ></besc-contribution-slider>
            </div>
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value2"
                    formControlName="value2"
                ></besc-contribution-slider>
            </div>
        </div>
        <div class="row" *ngIf="contributionsForm.value.type === 'composite'">
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value3"
                    formControlName="value3"
                ></besc-contribution-slider>
            </div>
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value4"
                    formControlName="value4"
                ></besc-contribution-slider>
            </div>
        </div>
    </div>
    <hr />
</div>
