<ng-container [formGroup]="ratesForm">
    <ng-container *ngIf="minMaxDates$ | async as minMaxDates">
        <div class="row" style="margin-bottom: 22px;">
            <div class="col-3">
                <label class="form-label">Plan effective from:</label>
                <besc-month-year-picker
                    class="quote-date"
                    formControlName="effectiveDate"
                    placeholder="Quote Effective Date"
                    required
                    [max]="minMaxDates.effective.max"
                    [min]="minMaxDates.effective.min"
                    [readonly]="true"
                    #effectiveDateControl
                ></besc-month-year-picker>
            </div>
            <div class="col-1"></div>
            <div class="col-3">
                <label class="form-label">Plan termination date:</label>
                <besc-month-year-picker
                    class="quote-date"
                    formControlName="expirationDate"
                    placeholder="Quote Expiration Date"
                    required
                    [min]="minMaxDates.expiration.min"
                    [effectiveDateControl]="effectiveDateControl"
                    [readonly]="true"
                ></besc-month-year-picker>
            </div>
        </div>
    </ng-container>
    <hr />
    <ng-container formGroupName="rates">
        <div class="btn-group" role="group" style="margin-bottom: 22px;">
            <input
                type="radio"
                class="btn-check"
                id="rateCompositeRates"
                formControlName="type"
                value="composite"
                autocomplete="off"
            />
            <label
                class="btn btn-outline btn-outline-secondary btn-composite"
                for="rateCompositeRates"
                >Composite</label
            >

            <ng-container>
                <input
                    type="radio"
                    class="btn-check"
                    id="rateAgeRates"
                    formControlName="type"
                    value="age_rate"
                    autocomplete="off"
                />
                <label
                    class="btn btn-outline btn-outline-secondary btn-age"
                    for="rateAgeRates"
                >
                    <besc-loading-indicator [loading]="ageCurveLoading$ | async">
                        Age Rated
                    </besc-loading-indicator>
                </label >
            </ng-container>
        </div>
    </ng-container>
    <div class="composite-rates" [hidden]="ratesForm.value.rates.type !== 'composite'">
        <ng-container formGroupName="rates">
            <div class="composite-form" formGroupName="composite">
                <div class="row">
                    <div class="col-md mb-4">
                        <label for="single" class="form-label required"
                            >Single</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="single"
                                class="form-control"
                                placeholder="Single"
                                type="number"
                                formControlName="single"
                                name="single"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                    <div class="col-md mb-4">
                        <label
                            for="singleAndChildren"
                            class="form-label required"
                            >Single and Children</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="singleAndChildren"
                                class="form-control"
                                placeholder="Single and Children"
                                type="number"
                                formControlName="singleAndChildren"
                                name="singleAndChildren"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mb-4">
                        <label for="singleAndSpouse" class="form-label required"
                            >Single and Spouse</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="singleAndSpouse"
                                class="form-control"
                                placeholder="Single and Spouse"
                                type="number"
                                formControlName="singleAndSpouse"
                                name="singleAndSpouse"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                    <div class="col-md mb-4">
                        <label for="family" class="form-label required"
                            >Family</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="family"
                                class="form-control"
                                placeholder="Family"
                                type="number"
                                formControlName="family"
                                name="family"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="age-rates-wrapper" [hidden]="ratesForm.value.rates.type !== 'age_rate' || (ageCurveLoading$ | async)">
        <div class="calculate-rates" *ngIf="ageCurve$ | async as ageCurve; else noRatesCurveTemplate">
            <p class="calculate-rates__hint">
                We’ve retreived the age rate curve associated with this employer’s area. To auto generate rates for this plan, input the field below.
            </p>
            <div class="row d-flex align-items-end">
                <div class="col-md-3">
                    <label for="age21Rate" class="form-label required"
                        >Age 21 Rate</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="age21Rate"
                            class="form-control"
                            placeholder="Single & spouse"
                            type="number"
                            formControlName="age21Rate"
                            name="age21Rate"
                            bescInputValidation
                            bescNumericInput
                            [decimals]="true"
                        />
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-check form-switch" style="margin-bottom: 13px;">
                        <input
                            class="form-check-input"
                            formControlName="hasTobaccoRates"
                            type="checkbox"
                            role="switch"
                            id="hasTobaccoRates"
                        />
                        <label class="form-check-label" for="hasTobaccoRates">
                            Tobacco rates?</label
                        >
                    </div>
                </div>
                <div class="col-md-3" *ngIf="ratesForm.controls.hasTobaccoRates.value">
                    <label for="age21TobaccoRate" class="form-label required"
                        >Age 21 Tobacco Rate</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="age21TobaccoRate"
                            class="form-control"
                            placeholder="Single & spouse"
                            type="number"
                            formControlName="age21TobaccoRate"
                            name="age21TobaccoRate"
                            bescInputValidation
                            bescNumericInput
                            [decimals]="true"
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <button
                        class="bs-button bs-tertiary ms-3"
                        [class]="(animateCalculateRates$ | async) ? 'pulse-shadow' : ''"
                        [disabled]="ratesForm.controls.age21Rate.invalid || ratesForm.controls.hasTobaccoRates.value && ratesForm.controls.age21TobaccoRate.invalid"
                        (click)="calculateRates(ageCurve)"
                    >
                        Calculate Rates
                    </button>
                </div>
            </div>

            <button class="see-rates-btn" (click)="toggleRatesTable()">
                {{ (hideRates$ | async) ? 'See rates chart' : 'Close rates chart'}}
            </button>
        </div>

        <ng-template #noRatesCurveTemplate>
            <p>
                Based on the given zip code, we could not retrieve the age rate
                curve associated with this geographic area. If you would like to
                auto generate the rates for this plan, please go to the
                <strong>Plan Setup</strong> and change the zip code
            </p>
        </ng-template>

        <div class="rates" [ngbCollapse]="hideRates$ | async" formGroupName="rates">
            <hr>
            <h4 style="margin-bottom: 16px;">Age rates</h4>
            <p
                *ngIf="
                    !ratesForm.pristine &&
                    ratesForm.get('rates.age').errors &&
                    ratesForm.get('rates.age').errors.allRatesMustHaveValue
                "
                class="text-danger"
            >
                All rates must have a value greater than $0.00
            </p>
            <div class="age-rates" formGroupName="age">
                <div class="age-rate-input" *ngFor="let age of ages">
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">$</div>
                        <div class="form-floating">
                            <input
                                [id]="'age' + age"
                                class="form-control"
                                placeholder="Single and Spouse"
                                type="number"
                                [formControlName]="'age' + age"
                                [name]="'age' + age"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                            <label [for]="'age' + age" class="required"
                                >Age {{ age }}</label
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="tobacco-rates" [hidden]="!ratesForm.controls.hasTobaccoRates.value"
            >
                <hr />
                <h4 style="margin-bottom: 16px;">Tobacco rates</h4>
                <p
                    *ngIf="
                        !ratesForm.pristine &&
                        ratesForm.get('rates.tobacco').errors &&
                        ratesForm.get('rates.tobacco').errors
                            .allRatesMustHaveValue
                    "
                    class="text-danger"
                >
                    All tobacco rates must have a value greater than $0.00
                </p>
                <div class="age-rates" formGroupName="tobacco">
                    <div class="age-rate-input" *ngFor="let age of ages">
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <div class="form-floating">
                                <input
                                    [id]="'age' + age"
                                    class="form-control"
                                    placeholder="Single and Spouse"
                                    type="number"
                                    [formControlName]="'age' + age"
                                    [name]="'age' + age"
                                    bescInputValidation
                                    bescNumericInput
                                    [decimals]="true"
                                />
                                <label [for]="'age' + age" class="required"
                                    >Age {{ age }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
