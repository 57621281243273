import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'besc-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrls: ['./base-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseLayoutComponent implements OnInit, OnDestroy {

    hasSidebar$ = new BehaviorSubject<boolean>(true);
    fullContent$ = new BehaviorSubject<boolean>(false);
    private unsubscribe: Subscription[] = [];

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.checkLayout(this.router.url);
        this.routingChanges();
    }

    checkLayout(url: string): void {
        this.hasSidebar$.next(false);
        this.fullContent$.next(false);

        const sidebarOptions = ['app/small-employers'];
        for (let i = 0; i < sidebarOptions.length; i += 1) {
            if (url && url.includes(sidebarOptions[i])) {
                this.hasSidebar$.next(true);
            }
        }

        const nonOptions = ['quotes'];
        for (let i = 0; i < nonOptions.length; i += 1) {
            if (url && url.includes(nonOptions[i])) {
                this.hasSidebar$.next(false);
            }
        }

        const fullOptions = ['home'];
        for (let i = 0; i < fullOptions.length; i += 1) {
            if (url && url.includes(fullOptions[i])) {
                this.fullContent$.next(true);
            }
        }
    }

    routingChanges() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkLayout(event.url);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
