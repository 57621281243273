import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';

import { Plan } from '@besc/plan';

@Component({
    selector: 'besc-plan-details-accordion',
    templateUrl: './plan-details-accordion.component.html',
    styleUrls: ['./plan-details-accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDetailsAccordionComponent implements OnDestroy {
    @Input() plan: Plan;
    @Input() planInstallation = false;
    @Output() private compositeRatesChange = new EventEmitter<any>();

    ratesForm: UntypedFormGroup = this._fb.group({
        rates: this._fb.group({
            composite: this._fb.group({
                single: [0],
                singleAndChildren: [0],
                singleAndSpouse: [0],
                family: [0],
            }),
            type: ['composite'],
        })
    });

    obsv$ = this.ratesForm.valueChanges.subscribe((rates) => {
        this.compositeRatesChange.emit(rates);
    })

    planDetailsExpanded = false;

    constructor(
        private _fb: UntypedFormBuilder
    ) {}

    ngOnDestroy(): void {
        if (this.obsv$) {
            this.obsv$.unsubscribe();
        }
    }
}
