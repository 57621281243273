/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PlanRates,
  CompositeRateRequest
} from '@besc/plan';

@Component({
  selector: 'besc-plan-rate-modal',
  templateUrl: './plan-rate-modal.component.html',
  styleUrls: ['./plan-rate-modal.component.scss']
})
export class PlanRateModalComponent implements OnInit {
  @Input() rates: PlanRates;
  @Input() compositeRateRequest$: Observable<CompositeRateRequest>;

  compositeRates$ = new BehaviorSubject(null);

  indexes: number[] = [...Array(6).keys()];
  columns: number[] = [...Array(11).keys()];

  constructor(
    private _modal: NgbActiveModal,
  ) { }

  ngOnInit() {
    if (this.rates.rates.type === 'composite') {
      this.compositeRates$.next(this.rates.rates.composite);
    }
  }

  close() {
    this._modal.close();
  }
}
