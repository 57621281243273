import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryTableComponent } from './summary-table.component';


@NgModule({
    imports: [CommonModule],
    exports: [SummaryTableComponent],
    declarations: [SummaryTableComponent],
})
export class SummaryTableModule {}
