import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { WizardEffects } from './+state/wizard.effects';
import { NextPrevButtonsComponent } from './components/next-prev-buttons/next-prev-buttons.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WizardFacade } from './+state/wizard.facade';
import { WIZARD_SELECTORS } from './+state/wizard.models';
import { getWizardSelectors } from './+state/wizard.selectors';
import { WizardStepsModule } from './components/wizard-steps/wizard-steps.module';

@NgModule({
    declarations: [NextPrevButtonsComponent],
    imports: [
        CommonModule,
        EffectsModule.forFeature([WizardEffects]),
        NgbProgressbarModule,
        FontAwesomeModule,
        WizardStepsModule
    ],
    exports: [NextPrevButtonsComponent]
})
export class WizardModule {
    static withFacade(
        facade: Type<WizardFacade>,
        path: string
    ): ModuleWithProviders<WizardModule> {
        return {
            ngModule: WizardModule,
            providers: [
                {
                    provide: WizardFacade,
                    useExisting: facade
                },
                {
                    provide: WIZARD_SELECTORS,
                    useValue: getWizardSelectors(path)
                }
            ]
        };
    }
}
