<div id="kt_navigation_bar_container" class="container-fluid navigation-bar-container">
    <div class="navigation-bar-inner d-flex align-items-center">
        <div
            *ngFor="let item of navigationItems$ | async"
            class="menu-item py-3"
            [ngClass]="{ 'menu-active': item.isActive }"
        >
            <a
                class="menu-link menu-center"
                [attr.href]="!item.disabled ? item.link : undefined"
                (click)="navigateItem(item, $event)"
                [ngClass]="{ disabled: item.disabled }"
            >
                <span class="menu-title text-center">
                    {{ item.label }}
                </span>
            </a>
        </div>
    </div>
</div>
