import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import * as moment from 'moment';
import { SmallEmployer } from '@benefit-sculptor/small-employer';
import { EmployeesFacade } from '@besc/employee';
import { ChangeListEmployee, EmployerPlan, UpdateEmployeesOnPlan } from '../../interfaces';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { PlanFacade } from '../../services/plan.facade';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
    filter,
    finalize,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    formatMomentProperties,
    getEffectiveDateForCurrentMonth,
    normalizeEffectiveDate,
    normalizeTerminationDate,
    sortCompareFn,
} from '@benefit-sculptor/core';
import { Router } from '@angular/router';
import { Confirmable } from '@besc/shared';
import { changeEmployeesPlanConfirmationParams } from '../../constants/ChangeEmployeesPlanConfirmationModal';
import { PlanDetailsModalComponent } from '../plan-details-modal/plan-details-modal.component';
import { ToastrService } from 'ngx-toastr';

enum EmployeeLists {
    OnPlan = 'employees-on-plan',
    OffPlan = 'employees-off-plan',
}

declare let heap: any;

@Component({
    selector: 'besc-change-employees-on-plan',
    templateUrl: './change-employees-on-plan.component.html',
    styleUrls: ['./change-employees-on-plan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmployeesOnPlanComponent implements OnInit, OnDestroy {

    private _router = inject(Router);
    private _destroy$: Subject<void> = new Subject<void>();
    private _employeesOnPlanBS: BehaviorSubject<ChangeListEmployee[]> = new BehaviorSubject<ChangeListEmployee[]>([]);
    private _employeesOffPlanBS: BehaviorSubject<ChangeListEmployee[]> = new BehaviorSubject<ChangeListEmployee[]>([]);

    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _reset: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    @Input() employer: SmallEmployer;
    @Input() currentPlan: EmployerPlan = null;

    @Output() changes: EventEmitter<ChangeListEmployee[]> = new EventEmitter<ChangeListEmployee[]>();

    today = moment();
    loading$ = this._loading.asObservable();

    employeesOnPlan$ = this._employeesOnPlanBS.asObservable();
    employeesOffPlan$ = this._employeesOffPlanBS.asObservable();
    employeePlans$ = this._employees.employeePlans$;

    currentPlanForm: UntypedFormGroup = this._fb.group({
        currentPlan: ['all', Validators.required],
        dateApplied: [getEffectiveDateForCurrentMonth(), Validators.required],
        dateFilter: [getEffectiveDateForCurrentMonth(), Validators.required],
    });
    currentPlanBS$: BehaviorSubject<EmployerPlan> = new BehaviorSubject<EmployerPlan>(null);

    findPlansDate$ = new BehaviorSubject<moment.Moment>(getEffectiveDateForCurrentMonth());

    employerPlans$: Observable<EmployerPlan[]> = this.findPlansDate$.pipe(
        switchMap((findPlansDate) => {
            if (!findPlansDate) {
                return of([]);
            }
            this._loading.next(true);
            return this._plan
                .all(this.employer.id, findPlansDate)
                .pipe(finalize(() => this._loading.next(false)));
        })
    );
    currentEmployees$: Observable<ChangeListEmployee[]> = this._employees.employeesForCurrentPlan$;
    employeesOffCurrentPlan$: Observable<ChangeListEmployee[]> = this._employees.employeesNotOnAnyPlan$;

    hasChanges$ = combineLatest([
        this.employeesOnPlan$,
        this.employeesOffPlan$,
    ]).pipe(
        map(([employeesOnPlan, employeesOffPlan]) => {
            for (const employeeOnPlan of employeesOnPlan) {
                if (employeeOnPlan?.changing) {
                    return true;
                }
            }
            for (const employeeOffPlan of employeesOffPlan) {
                if (employeeOffPlan?.changing) {
                    return true;
                }
            }
            return false;
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    refreshPlan = false;
    changesList: { changes: UpdateEmployeesOnPlan, plan: EmployerPlan};
    changeEmployeesPlanConfirmationParams = changeEmployeesPlanConfirmationParams;

    constructor(
        private _employees: EmployeesFacade,
        private _modal: NgbModal,
        private _activeModal: NgbActiveModal,
        private _plan: PlanFacade,
        private _fb: UntypedFormBuilder,
        private _toastr: ToastrService
    ) {}

    ngOnInit() {
        this.getCurrentPlanBS();
        this.watchDateFilterValueChanges();
        this.watchDateAppliedValueChanges();
        this.watchCurrentPlanValueChanges();
        this.watchCurrentEmployeesValueChanges();
        this.setCurrentPlan();
    }

    setCurrentPlan(): void {
        if (this.currentPlan) {
            this.currentPlanForm.get('currentPlan').setValue(this.currentPlan.id);
        } else {
            this._employees.setCurrentEmployerPlan(this.currentPlanForm.value.currentPlan);
        }
    }

    watchDateFilterValueChanges(): void {
        this.currentPlanForm
            .get('dateFilter')
            .valueChanges.pipe(
                tap(() => this.reset()),
                tap(findEmployeesDate => this.loadEmployees(findEmployeesDate)),
                tap(findPlansDate => this.findPlansDate$.next(findPlansDate)),
                takeUntil(this._destroy$)
            )
            .subscribe();
    }

    watchDateAppliedValueChanges(): void {
        this.currentPlanForm
            .get('dateApplied')
            .valueChanges.pipe(
                tap(findEmployeesDate => this.loadEmployees(findEmployeesDate)),
                takeUntil(this._destroy$)
            )
            .subscribe();
    }

    watchCurrentPlanValueChanges(): void {
        this.currentPlanForm
            .get('currentPlan')
            .valueChanges.pipe(
                filter((currentPlanId) => !!currentPlanId),
                tap(currentPlan => this._employees.setCurrentEmployerPlan(currentPlan)),
                tap(() => this.clearChanges()),
                tap(() => this.updateDateAppliedValue(this.currentPlanForm.get('dateFilter').value)),
                takeUntil(this._destroy$),
            )
            .subscribe();
    }

    watchCurrentEmployeesValueChanges(): void {
        combineLatest([
            this.currentEmployees$,
            this.employeesOffCurrentPlan$,
            this._reset,
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([currentEmployees, employees]) => {
                this._employeesOnPlanBS.next([...currentEmployees].filter((currentEmployee) => !!currentEmployee));
                this._employeesOffPlanBS.next([...employees].filter((currentEmployee) => !!currentEmployee));
            });
    }

    updateDateAppliedValue(date: moment.Moment): void {
        this.currentPlanForm.get('dateApplied').setValue(date);
    }

    loadEmployees(effectiveDate: moment.Moment) {
        this._employees.loadEmployees(this.employer.id, effectiveDate)
    }

    getCurrentPlanBS(): void {
        combineLatest([
            this.currentPlanForm.get('currentPlan').valueChanges,
            this.employerPlans$,
        ])
        .pipe(
            tap(([currentPlan, employerPlans]) => {
                if (currentPlan === 'all') {
                    return this.currentPlanBS$.next(null);
                }
                const employerPlan = employerPlans.find(plan => plan.id === currentPlan);
                this.currentPlanBS$.next(employerPlan);
            }),
            takeUntil(this._destroy$)
        )
        .subscribe();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    close() {
        this.reset();
        this._activeModal.close({ refreshPlan: this.refreshPlan });
    }

    @Confirmable('changeEmployeesPlanConfirmationParams', 'changesList')
    attachEmployeesToPlan() {
        this.refreshPlan = true;
        this._loading.next(true);

        this._plan
            .assignEmployeesToPlan(
                this.currentPlan
                    ? this.currentPlan.id
                    : this.currentPlanForm.get('currentPlan').value,
                this.changesList.changes
            )
            .pipe(finalize(() => this._loading.next(false)))
            .subscribe(() => {
                this.clearChanges();
                this._toastr.success(
                    `${this.changesList.changes.length} employee plan assignments have been updated`,
                    '',
                    {
                        positionClass: 'toast-center',
                    }
                );
                this._employees.loadEmployeePlans(this.employer.id, this.currentPlanForm.get('dateApplied').value);
            });
    }

    setChanges(): void {
        const employeesOffPlan: ChangeListEmployee[] = this._employeesOffPlanBS.getValue();
        const employeesOnPlan: ChangeListEmployee[] = this._employeesOnPlanBS.getValue();
        const changes = [
            ...employeesOffPlan
            .filter((employee) => !!employee.changing)
            .map((employee) => ({
                employeeId: employee.id,
                terminationDate: employee.terminationDate,
            })),
            ...employeesOnPlan
            .filter((employee) => !!employee.changing)
            .map((employee) => ({
                employeeId: employee.id,
                effectiveDate: employee.effectiveDate,
            })),
        ].map((change) => formatMomentProperties(change));
        this.changesList = { changes, plan: this.currentPlanBS$.value };
    }

    reset() {
        this.clearChanges();
        if (!this.currentPlan) {
            this.currentPlanForm.get('currentPlan').setValue('all');
        }
        this._reset.next(null);
    }

    clearChanges(): void {
        for (const employee of this._employeesOnPlanBS.getValue()) {
            employee.changing = false;
        }
        for (const employee of this._employeesOffPlanBS.getValue()) {
            employee.changing = false;
        }
    }

    moveEmployee($event: CdkDragDrop<ChangeListEmployee[], ChangeListEmployee[], ChangeListEmployee>) {
        if ($event.container !== $event.previousContainer) {
            const dateApplied = this.currentPlanForm.get('dateApplied').value;
            $event.item.data.changing = !$event.item.data.changing;
            $event.item.data.terminationDate = normalizeTerminationDate(dateApplied);
            $event.item.data.effectiveDate = normalizeEffectiveDate(dateApplied);
            const [changedEmployees, employees] = this._splitOutChangingEmployees($event.container.data);
            const dragAction = $event.container.id === EmployeeLists.OffPlan ? 'Unassigned Employee' : 'Assigned Employee';
             heap.track(`Drag - ${dragAction}`, {
                id: $event.item.data.id,
                employerId: $event.item.data.employerId
             });

            this.updateEmployeesList(
                $event.previousContainer.data,
                $event.item.data.changing,
                changedEmployees,
                employees,
                $event.previousIndex,
                $event.container.id === EmployeeLists.OffPlan ? EmployeeLists.OnPlan : EmployeeLists.OffPlan,
            );
        }
    }

    moveToOtherList($event: {
        index: any;
        employee: ChangeListEmployee;
        list: EmployeeLists;
    }) {
        if (this.currentPlanForm.value.currentPlan === 'all') {
            return;
        }
        const dateApplied = this.currentPlanForm.get('dateApplied').value;
        $event.employee.terminationDate = normalizeTerminationDate(dateApplied),
        $event.employee.effectiveDate = normalizeEffectiveDate(dateApplied),
        $event.employee.changing = !$event.employee.changing;
        const { previous, current } = this._getEmployeesFromListType($event.list);
        const [changedEmployees, employees] = this._splitOutChangingEmployees(current);

        this.updateEmployeesList(
            previous,
            $event.employee.changing,
            changedEmployees,
            employees,
            $event.index,
            $event.list
        );
    }

    updateEmployeesList(
        previous: ChangeListEmployee[],
        changing: boolean,
        changedEmployees: ChangeListEmployee[],
        employees: ChangeListEmployee[],
        previousIndex: number,
        list: EmployeeLists
    ): void {
        transferArrayItem(
            previous,
            changing ? changedEmployees : employees,
            previousIndex,
            0
        );
        const newEmployees = [
            ...changedEmployees.sort(sortCompareFn(['lastName', 'firstName'], 'asc')),
            ...employees.sort(sortCompareFn(['lastName', 'firstName'], 'asc')),
        ];
        if (list !== EmployeeLists.OffPlan) {
            this._employeesOffPlanBS.next(newEmployees);
            this._employeesOnPlanBS.next(previous);
        } else {
            this._employeesOnPlanBS.next(newEmployees);
            this._employeesOffPlanBS.next(previous);
        }
    }

    navigateToEmployees(): void {
        this.close();
        this._router.navigateByUrl(`app/small-employers/${this.employer.id}/employees`);
    }

    displayPlanDetails(): void {
        const planDetailsModalRef = this._modal.open(PlanDetailsModalComponent);
        const instance = planDetailsModalRef.componentInstance as PlanDetailsModalComponent;
        instance.plan = this.currentPlanBS$.value;
    }

    private _getEmployeesFromListType(listType: EmployeeLists) {
        return {
            previous:
                listType === EmployeeLists.OffPlan
                    ? this._employeesOffPlanBS.getValue()
                    : this._employeesOnPlanBS.getValue(),
            current:
                listType === EmployeeLists.OffPlan
                    ? this._employeesOnPlanBS.getValue()
                    : this._employeesOffPlanBS.getValue(),
        };
    }

    private _splitOutChangingEmployees(employees: ChangeListEmployee[]) {
        const changedItemsIndex = employees.findIndex(employee => !employee.changing);
        if (changedItemsIndex === -1) {
            return [employees, []];
        } else if (changedItemsIndex === 0) {
            return [[], employees];
        }
        const changedEmployees = employees.splice(0, changedItemsIndex);
        return [changedEmployees, employees];
    }
}
