import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

import {
    AgeRateCurveResponse,
    CreateExistingPlanRequest,
    EmployerHealthPlanContribution,
    EmployerPlan,
    Issuer,
    Plan,
    SimplePlan,
    UpdateEmployeesOnPlan,
} from '../interfaces';
import {
    formatMomentProperties,
    normalizeEffectiveDate,
} from '@benefit-sculptor/core';
import { Employee } from '@besc/employee';
import { ActuarialValuePayload, ActuarialValueResponse } from '../create-plans/models/ActuarialValue.model';
import { FullPlan } from '../create-plans/interfaces';
import { AgencyCustomPlan, AgencyCustomPlanEmployers, AgencyCustomPlanOverview } from '@besc/agency-custom-plans';

@Injectable({
    providedIn: 'root',
})
export class PlanFacade {
    all$: Observable<EmployerPlan>;

    constructor(
        private _http: HttpClient,
    ) {}

    all(
        employerId: string,
        effectiveDate: moment.Moment
    ): Observable<EmployerPlan[]> {
        const params = new HttpParams().append(
            'effectiveDate',
            normalizeEffectiveDate(effectiveDate).format(moment.HTML5_FMT.DATE)
        );
        return this._http.get<EmployerPlan[]>(
            'employers/' + employerId + '/plans/',
            {
                params,
            }
        );
    }

    quotes(
        employerId: string,
        effectiveDate: moment.Moment
    ): Observable<EmployerPlan[]> {
        const params = new HttpParams()
            .append(
                'effectiveDate',
                normalizeEffectiveDate(effectiveDate).format(
                    moment.HTML5_FMT.DATE
                )
            )
            .append('planStatus', 'quote')
            .append('healthPlanType', 'full');
        return this._http.get<EmployerPlan[]>(
            'employers/' + employerId + '/plans/',
            {
                params,
            }
        );
    }

    getEmployees(planId: string): Observable<Employee[]> {
        return this._http.get<Employee[]>(
            'employer-plans/' + planId + '/employees'
        );
    }

    assignEmployeesToPlan(planId: string, update: UpdateEmployeesOnPlan) {
        return this._http.post(
            'employer-plans/' + planId + '/employees',
            update
        );
    }

    upsertContributionsToPlan(
        planId: string,
        update: EmployerHealthPlanContribution
    ): Observable<EmployerHealthPlanContribution> {
        return this._http.post<EmployerHealthPlanContribution>(
            'employer-plans/' + planId + '/contributions',
            update
        );
    }

    assignEmployees(update: UpdateEmployeesOnPlan) {
        return this._http.post('employer-plans/employees', update);
    }

    getEmployerPlan(
        employerId: string,
        planId: string
    ): Observable<EmployerPlan> {
        const params = new HttpParams().append(
            'employerId',
            employerId
        );
        return this._http.get<EmployerPlan>('employer-plans/' + planId, {params});
    }

    getEmployerPlanWithDate(
        employerId: string,
        planId: string,
        effectiveDate: string,
    ): Observable<EmployerPlan> {
        const params = new HttpParams()
            .append('effectiveDate', effectiveDate)
            .append('employerId', employerId);
        return this._http.get<EmployerPlan>('employer-plans/' + planId, {params});
    }

    updateEmployerPlan(
        employerPlanId: string,
        update: Partial<
            Pick<
                EmployerPlan,
                | 'planGenericName'
                | 'effectiveDate'
                | 'terminationDate'
                | 'planStatus'
            >
        >
    ) {
        return this._http.put(
            'employer-plans/' + employerPlanId,
            formatMomentProperties(update)
        );
    }

    deleteEmployerPlan(employerPlanId: string) {
        return this._http.delete('employer-plans/' + employerPlanId);
    }

    getAgeRateCurve(zipCode: string): Observable<AgeRateCurveResponse> {
        return this._http.get<AgeRateCurveResponse>(
            'health-insurance-market/age-rate-curve/' + zipCode
        );
    }

    getAllAgeRateCurves(): Observable<AgeRateCurveResponse[]> {
        return this._http.get<AgeRateCurveResponse[]>(
            'health-insurance-market/age-rate-curve/'
        );
    }

    getCurrentIssuers(zipCode: string, year: string) {
        return this._http.get<Issuer[]>('health-insurance-market/issuers', {
            params: {
                zip_code: zipCode,
                year,
            },
        });
    }

    getSimplePlanList(year: string) {
        const params = new HttpParams().append('year', year);
        return this._http
            .get<{ plan: SimplePlan }[]>('/agencies/custom-plans', {
                params,
            })
            .pipe(
                map((overviews) => {
                    return overviews.map(({ plan }) => plan);
                })
            );
    }

    get(planId: string, zipCode: string, effectiveDate: string, employerId: string=null): Observable<FullPlan> {
        let params = new HttpParams();
        params = params.set('zip_code', zipCode);
        params = params.set('effective_date', effectiveDate);
        if (employerId) {
            params = params.set('employer_id', employerId);
        }
        return this._http.get<FullPlan>(
            'health-insurance-market/health-plan/' + planId,
            {
                params,
            }
        );
    }

    createCustomPlan(plan: Plan): Observable<AgencyCustomPlanOverview> {
        const updated = {
            ...plan,
            issuer: {
                ...plan.issuer,
                nameAbbreviated: plan.issuer.name
            }
        }
        return this._http.post<AgencyCustomPlanOverview>(
            '/agencies/custom-plans',
            formatMomentProperties(updated)
        );
    }

    createQuotePlan(
        employerId: string,
        plan: Plan & { employerZipCode: string }
    ): Observable<EmployerPlan> {
        return this._http.post<EmployerPlan>(
            `employers/${employerId}/plans/quote`,
            plan
        );
    }

    editQuotePlan(
        employerId: string,
        plan: Plan & { employerZipCode: string }
    ): Observable<EmployerPlan> {
        return this._http.put<EmployerPlan>(
            `employers/${employerId}/plans/assignment`,
            plan
        );
    }

    addExistingPlanToEmployer(
        employerId: string,
        request: CreateExistingPlanRequest
    ) {
        return this._http.post('employers/' + employerId + '/plans', request);
    }

    calculateActuarialValue(payload: ActuarialValuePayload): Observable<ActuarialValueResponse> {
        return this._http.post<ActuarialValueResponse>('health-insurance-market/actuarial-value/', payload);
    }

    getAgencyCustomPlan(id: string): Observable<AgencyCustomPlan> {
        return this._http.get<AgencyCustomPlan>(`/agencies/custom-plans/${id}`);
    }

    getAgencyCustomPlanEmployers(id: string): Observable<AgencyCustomPlanEmployers> {
        return this._http.get<AgencyCustomPlanEmployers>(
            `/agencies/custom-plans/employers/${id}`
        );
    }
}
