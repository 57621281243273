<h1 class="plan_full_details__title">
    {{ planOverview ? planOverview.issuer.name : plan.issuer.issuer.name }}
</h1>
<h1 class="plan_full_details__title" style="margin-bottom: 24px">
    {{ planOverview ? planOverview.planName : plan.name }}
    <button *ngIf="plan && plan.sbcLink" class="plan_full_details__downloadSBC">
        <a [href]="plan.sbcLink" target="_blank">
            Download SBC 
            <img src="assets/icons/open_in_new.svg">
        </a>
    </button>
</h1>
<bs-close-button (click)="close()" class="plan_full_details__close_btn"></bs-close-button>

<mat-accordion>

    <!-- Plan details -->
    <mat-expansion-panel
        *ngIf="{ value: planDetailsOpenState$ | async } as planDetailsOpenState"
        hideToggle
        [expanded]="true"
        (opened)="planDetailsOpenState$.next(true)"
        (closed)="planDetailsOpenState$.next(false)"
    >
        <mat-expansion-panel-header [class.expanded]="planDetailsOpenState.value">
            <p>
                Plan details
                <i *ngIf="!planDetailsOpenState" class="fa fa-chevron-down accordion-icon" aria-hidden="true"></i>
                <i *ngIf="planDetailsOpenState" class="fa fa-chevron-up accordion-icon" aria-hidden="true"></i>
                <span>{{ planDetailsOpenState.value ? 'Close' : 'View' }}</span>
            </p>
        </mat-expansion-panel-header>
        <besc-summary-table [data]="planDetails$ | async" class="plan_full_details__panel_body"></besc-summary-table>
     </mat-expansion-panel>

    <!-- Rx Benefits -->
    <mat-expansion-panel
        *ngIf="{ value: rxBenefitsOpenState$ | async } as rxBenefitsOpenState"
        hideToggle
        (opened)="rxBenefitsOpenState$.next(true)"
        (closed)="rxBenefitsOpenState$.next(false)"
    >
        <mat-expansion-panel-header [class.expanded]="rxBenefitsOpenState.value">
            <p>
                Rx Benefits
                <i *ngIf="!rxBenefitsOpenState" class="fa fa-chevron-down accordion-icon" aria-hidden="true"></i>
                <i *ngIf="rxBenefitsOpenState" class="fa fa-chevron-up accordion-icon" aria-hidden="true"></i>
                <span>{{ rxBenefitsOpenState.value ? 'Close' : 'View' }}</span>
            </p>
        </mat-expansion-panel-header>
        <besc-summary-table [data]="rxBenefits$ | async" class="plan_full_details__panel_body"></besc-summary-table>
    </mat-expansion-panel>

    <!-- Additional Plan Benefits -->
    <mat-expansion-panel
        *ngIf="{ value: additionalBenefitsOpenState$ | async } as additionalBenefitsOpenState"
        hideToggle
        (opened)="additionalBenefitsOpenState$.next(true)"
        (closed)="additionalBenefitsOpenState$.next(false)"
    >
        <mat-expansion-panel-header [class.expanded]="additionalBenefitsOpenState.value">
            <p>
                Additional Plan Benefits
                <i *ngIf="!additionalBenefitsOpenState" class="fa fa-chevron-down accordion-icon" aria-hidden="true"></i>
                <i *ngIf="additionalBenefitsOpenState" class="fa fa-chevron-up accordion-icon" aria-hidden="true"></i>
                <span>{{ additionalBenefitsOpenState.value ? 'Close' : 'View' }}</span>
            </p>
        </mat-expansion-panel-header>
        <besc-summary-table [data]="additionalBenefits$ | async" class="plan_full_details__panel_body"></besc-summary-table>
    </mat-expansion-panel>

    <!-- Plan rates -->
    <ng-container *ngIf="rates$ | async as rates">
        <ng-container *ngIf="rates.length">
            <mat-expansion-panel
                *ngIf="{ value: planRatesOpenState$ | async } as planRatesOpenState"
                hideToggle
                (opened)="planRatesOpenState$.next(true)"
                (closed)="planRatesOpenState$.next(false)"
            >
                <mat-expansion-panel-header [class.expanded]="planRatesOpenState.value">
                    <p>
                        Plan rates
                        <i *ngIf="!planRatesOpenState" class="fa fa-chevron-down accordion-icon" aria-hidden="true"></i>
                        <i *ngIf="planRatesOpenState" class="fa fa-chevron-up accordion-icon" aria-hidden="true"></i>
                        <span>{{ planRatesOpenState.value ? 'Close' : 'View' }}</span>
                    </p>
                </mat-expansion-panel-header>
                <besc-summary-table [data]="rates" [colSize]="rateType === 'composite' ? 6 : 4" [highlightKey]="true" class="plan_full_details__panel_body"></besc-summary-table>
            </mat-expansion-panel>
        </ng-container>
    </ng-container>
</mat-accordion>