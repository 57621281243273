import { FormControl, FormGroup } from "@angular/forms"
import * as moment from "moment"
import { RateType } from "../../interfaces";

export interface RatesForm {
    effectiveDate: FormControl<moment.Moment>;
    expirationDate: FormControl<moment.Moment>;
    rates: FormGroup<RatesDetailsForm>;
    age21Rate: FormControl<number>;
    hasTobaccoRates: FormControl<boolean>;
    age21TobaccoRate: FormControl<number>;
    id: FormControl<string>;
}

export interface RatesDetailsForm {
    composite: FormGroup<CompositeForm>;
    age: FormGroup<AgeRateForm>;
    tobacco: FormGroup<AgeRateForm>;
    type: FormControl<RateType>;
}

export interface CompositeForm {
    single: FormControl<number>;
    singleAndChildren: FormControl<number>;
    singleAndSpouse: FormControl<number>;
    family: FormControl<number>;
}

type GenerateAgeKeys<Num extends number, Acc extends number[] = []> = 
  Acc['length'] extends Num 
    ? Acc[number] 
    : GenerateAgeKeys<Num, [...Acc, Acc['length']]>;

export type AgeKeys = `age${GenerateAgeKeys<66>}`;

export type AgeRateForm = Record<AgeKeys, FormControl<number>>;

export type AgeRateFormValues = Record<AgeKeys, number>;

export const getAgeRateFormControls = (): AgeRateForm => {
    const controls = {} as AgeRateForm;
    for (let index = 0; index < 66; index++) {
        controls[`age${index}`] = new FormControl(null);
    }
    return controls;
}

