import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WizardStepsComponent } from "./wizard-steps.component";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    imports: [CommonModule, NgbProgressbarModule, FontAwesomeModule],
    declarations: [WizardStepsComponent],
    exports: [WizardStepsComponent],
})
export class WizardStepsModule {}