import { environment } from 'apps/benefit-sculptor/src/environments/environment';

export function getStateAndCityFrom(zipCode: string): Promise<{ state: string; city: string }> {
    return new Promise((resolve, reject) => {
        fetch(`https://www.zipcodeapi.com/rest/${environment.zipCodeAPIKey}/info.json/${zipCode}/radians`)
            .then(async response => {
                const data = await response.json();
                return resolve(data);
            })
            .catch(error => {
                return reject(error);
            });
    });
}