/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Plan } from '../../interfaces';
import { PlanRates } from '@besc/plan';
import { PlanRateModalComponent } from '../plan-rate-modal/plan-rate-modal.component';
import { CompositeRateRequest } from '@besc/plan';
import { Employee, EmployeesFacade } from '@besc/employee';

@Component({
	selector: 'besc-contributions',
	templateUrl: './contributions.component.html',
	styleUrls: ['./contributions.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ContributionsComponent),
			multi: true,
		}
	],
})
export class ContributionsComponent
	implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() plan$: Observable<Plan>;
	@Input() carrierBillingType$: Observable<string>;
	@Input() compositeRateRequest$: Observable<CompositeRateRequest>;

	rateType: string;
	initialType: string;
	billingType: string;
	rates: PlanRates;

	private _onDestroy$ = new Subject();
	contributionsForm: UntypedFormGroup = this._fb.group({
		type: ['rate_build_up', Validators.required],
		value1: [{ method: 'percentage', value: 100 }],
		value2: [{ method: 'percentage', value: 100 }],
		value3: [{ method: 'percentage', value: 100 }],
		value4: [{ method: 'percentage', value: 100 }],
	});

	contributionsLabels$: Observable<any>;

	employees$: Observable<Employee[]> = this._employees.filteredEmployees$;
    employeesLoaded$: Observable<boolean> = this._employees.loaded$;
	hasEmployees$: Observable<boolean>;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChangeFn = (_: any) => {};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouchedFn = () => {};

	constructor(
		private _fb: UntypedFormBuilder,
		private _modal: NgbModal,
		private _employees: EmployeesFacade,
	) { }

	ngOnInit(): void {
		this.getEmployees();
		this.contributionsForm.valueChanges
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((value) => {
				this.onChangeFn(value);
				this.onTouchedFn();
			});
		if (this.plan$) {
			this.plan$
			.pipe(
				takeUntil(this._onDestroy$)
			)
			.subscribe((plan) => {
				if (plan) {
					this.rateType = plan.planRates?.rates.type;
					this.rates = plan.planRates;
					this.contributionsForm
						.get('type')
						.patchValue(
							this.rateType == 'composite'
								? 'composite'
								: 'rate_build_up'
						);
				}
			});
		}

		this.carrierBillingType$
			?.pipe(takeUntil(this._onDestroy$))
			.subscribe((type) => {
				this.billingType = type;
				if (type === 'composite') {
					this.contributionsForm.get('type').patchValue(type);
				}
			});
	}

	getEmployees() {
        this.hasEmployees$ = combineLatest([
            this.employees$,
            this.employeesLoaded$
        ]).pipe(
            map(([employees, loaded]) => loaded && employees.length > 0)
        );
    }

	watchContributionLabels(): void {
		if (!this.contributionsLabels$) {
			this.contributionsLabels$ = this.contributionsForm
				.get('type')
				.valueChanges.pipe(
					startWith(
						this.initialType == 'composite' ? 'composite' : 'rate_build_up'
					),
					map((value) => {
						if (value === 'rate_build_up') {
							return {
								value1: 'Employee',
								value2: 'Dependents',
							};
						}
	
						return {
							value1: 'Employee Only',
							value2: 'Employee & Spouse',
							value3: 'Employee & Children',
							value4: 'Family',
						};
					})
				);
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
	}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}

	writeValue(obj: any): void {
		if (obj?.type === 'composite') {
			this.initialType = 'composite';
		}
		this.watchContributionLabels();
		if (obj) {
			this.contributionsForm.setValue(obj, { emitEvent: false });
		} else {
			this.contributionsForm.reset({
				type: 'rate_build_up',
				value1: { method: 'percentage', value: 100 },
				value2: { method: 'percentage', value: 100 },
				value3: { method: 'percentage', value: 100 },
				value4: { method: 'percentage', value: 100 },
			});
		}
		this.contributionsForm.markAsPristine();
	}

	openPlanRatesModal() {
		const modalRef = this._modal.open(PlanRateModalComponent, {
			size: 'lg',
		});
		const modalComponent = modalRef.componentInstance as PlanRateModalComponent;
		modalComponent.rates = this.rates;
		modalComponent.compositeRateRequest$ = this.compositeRateRequest$;
	}
}
