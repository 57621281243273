<div class="row" style="width: 100%; margin: 0">
    <div
        *ngFor="let item of data"
        class="d-flex"
        [ngClass]="'col-' + colSize"
        style="padding: 0; border-right: 1px solid #97979766"
        [class.summary_table__header]="item.isHeader"
    >
        <div class="summary_table__item" [class.summary_table__time_highlighted]="highlightKey">
            {{ item.label | titlecase }}
        </div>
        <div class="summary_table__item">
            {{ item.value }}
            <button class="summary_table__action_btn" (click)="actionClicked.emit()">
                {{ item.action }}
            </button>
        </div>
    </div>
</div>