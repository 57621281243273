import { FullPlan } from '../interfaces';
import { Issuer } from '@besc/plan';
import { CreatePlansStep } from '../steps/steps';

export function tranformPlanToForm(plan: FullPlan) {
    return {
        [CreatePlansStep.Issuer]: getIssuerFromPlan(plan.issuer.issuer),
        [CreatePlansStep.PlanInfo]: getPlanInfo(plan),
        [CreatePlansStep.PlanDetails]: getPlanDetails(plan),
        [CreatePlansStep.CoverageDetails]: getPlanCoverageDetails(plan),
        [CreatePlansStep.Rates]: plan.planRates,
    };
}

export function getIssuerFromPlan(issuer: Issuer) {
    return {
        type: 'existing',
        existingId: {
            id: issuer.id,
            name: issuer.name,
        },
    };
}

export function getPlanInfo(plan: FullPlan) {
    return {
        name: plan.name,
        ...plan.info,
    };
}

export function getPlanDetails(plan: FullPlan) {
    return {
        ...plan.details,
        coinsuranceMember: plan.details.coinsuranceMember * 100,
        metallicLevel: plan.info.metallicLevel,
    };
}

export function getPlanCoverageDetails(plan: FullPlan) {
    return plan.coverage;
}

// export function getPlanRates(planRates: PlanRates) {
//     const rates = {
//         rateType: planRates.rateType,
//         rates: {
//             composite: {
//                 single: planRates.single,
//                 childOnly: planRates.childOnly,
//                 singleAndChildren: planRates.singleAndChildren,
//                 singleAndSpouse: planRates.singleAndSpouse,
//                 family: planRates.family
//             },
//             tobaccoRates: {},
//             rates: {}
//         }
//     };
//
//     for (let i = 0; i < 66; i++) {
//         rates.rates.rates['age' + i] = planRates['age' + 0];
//         rates.rates.tobaccoRates['age' + i + 'Tobacco'] =
//             planRates['age' + i + 'Tobacco'];
//     }
//
//     return rates;
// }
