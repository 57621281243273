<div class="modal-header">
    <h4 class="modal-title">Plan Rates</h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="close()"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body">
  <div class="btn-group">
    <label *ngIf="rates.rates.type === 'age_rate'" class="btn btn-primary" style="cursor: auto">
      Age Rates
    </label>
    <label *ngIf="compositeRates$ | async" class="btn btn-primary" style="cursor: auto">
      Composite
    </label>
  </div>
  <div *ngIf="rates">
    <div *ngIf="rates.rates.type === 'composite'; else ageRatesTemplate" class="card">
      <div class="card-body" *ngIf="compositeRates$ | async as compositeRates">
        <div class="composite-table my-4">
          <div class="composite-item px-4">
            <h6 class="title pb-2 mb-2 border-bottom">Employee Only</h6>
            <p class="item">
              <strong>{{ compositeRates.single | currency }}</strong>
            </p>
          </div>
          <div class="composite-item px-4">
            <h6 class="title pb-2 mb-2 border-bottom">Employee & Spouse</h6>
            <p class="item">
              <strong>{{ compositeRates.singleAndSpouse | currency }}</strong>
            </p>
          </div>
          <div class="composite-item px-4">
            <h6 class="title pb-2 mb-2 border-bottom">Employee & Children</h6>
            <p class="item">
              <strong>{{ compositeRates.singleAndChildren | currency }}</strong>
            </p>
          </div>
          <div class="composite-item px-4">
            <h6 class="title pb-2 mb-2 border-bottom">Employee & Family</h6>
            <p class="item">
              <strong>{{ compositeRates.family | currency }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <ng-template #ageRatesTemplate>
      <div class="card">
        <div class="card-body">
          <div class="age-table my-4">
            <div class="age-column" *ngFor="let index of indexes">
              <div class="age-item py-2" *ngFor="let column of columns">
                <strong>Age {{ index * 11 + column }}:</strong>
                {{ rates.rates.age['age' + (index * 11 + column)] | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
  <ng-template #loadingBlock>
    <div class="loading">
      <mat-spinner class="center" [diameter]="40" color="primary"></mat-spinner>
    </div>
  </ng-template>
</div>
<div class="modal-footer"></div>
